// Types
import { MemorabiliaProductType } from 'api/graphql-global-types';
import { ParsedUrlQuery } from 'querystring';

export const getIsMemorabiliaSrpPage = (query: ParsedUrlQuery): boolean => {
  return query?.productType === MemorabiliaProductType.Memorabilia;
};

export const getIsProductSrpPage = (query: ParsedUrlQuery): boolean => {
  return query?.productType === MemorabiliaProductType.Product;
};

export const getIsMemorabiliaAndProductSrpPage = (
  query: ParsedUrlQuery,
  pathname: string
): boolean => {
  return pathname === '/products'
    ? true
    : query?.productType?.includes(MemorabiliaProductType.Product) &&
      query?.productType?.includes(MemorabiliaProductType.Memorabilia)
    ? true
    : false;
  false;
};
