import { gql } from '@apollo/client';
// Fragments
import { ExperienceFields, ExperienceRequestFields } from './fragments';

export const GET_EXPERIENCES = gql`
  query GetExperiences($input: ExperienceFilterInput!) {
    getExperiences(input: $input) {
      entities {
        ...ExperienceFields
      }
      filters {
        price
        sports {
          id
          name
        }
      }
      limit
      offset
      total
    }
  }
  ${ExperienceFields}
`;

export const GET_EXPERIENCE_BY_ID = gql`
  query GetExperienceById($id: String!) {
    getExperienceById(id: $id) {
      id
      title
      slug
      requestedPrice
      numberOfUnits
      status
      description
      price
      images {
        experienceImageFileKey
        isMainImage
        experienceId
        id
      }
      mainImageUrl
      hashtags {
        id
        name
      }
    }
  }
`;

export const SEARCH_EXPERIENCES = gql`
  query SearchExperiences($input: ExperienceFilterInput!) {
    getExperiences(input: $input) {
      entities {
        id
        title
        slug
      }
    }
  }
`;

/**
 * Get experiences with minimal info to display in related products list
 */
export const GET_EXPERIENCES_MINIMAL_INFO = gql`
  query GetExperiencesMinimalInfo($input: ExperienceFilterInput!) {
    getExperiences(input: $input) {
      entities {
        id
        description
        status
        title
        numberOfUnits
        price
        slug
        store {
          id
          firstName
          lastName
          slug
          role
          storeDetails {
            id
            storeName
          }
        }
        images {
          id
          isMainImage
          experienceImageFileKey
        }
      }
    }
  }
`;

export const GET_EXPERIENCE_BY_SLUG = gql`
  query GetExperienceBySlug($slug: String!, $storeSlug: String) {
    getExperienceBySlug(slug: $slug, storeSlug: $storeSlug) {
      id
      description
      status
      title
      numberOfUnits
      hashtags {
        name
        id
      }
      price
      slug
      store {
        id
        firstName
        lastName
        slug
        role
        storeDetails {
          storeName
        }
      }
      images {
        id
        isMainImage
        experienceImageFileKey
      }
    }
  }
`;

export const GET_EXPERIENCE_SALES = gql`
  query GetExperienceSales($input: ExperienceSalesInput!) {
    getExperienceSales(input: $input) {
      total
      offset
      limit
      entities {
        createdAt
        customer {
          id
          lastName
          firstName
        }
        customerId
        declineReason
        email
        earning
        experience {
          id
          comment
          description
          requestedPrice
          status
          title
          createdAt
          deletedAt
          numberOfUnits
          price
          slug
          images {
            id
            isMainImage
            experienceId
            experienceImageFileKey
          }
        }
        store {
          id
          slug
          role
          storeDetails {
            id
            storeName
            avatarURL
          }
        }
        experienceId
        id
        paidAt
        paymentMethodId
        price
        quantity
        requestStatus
        requestedPrice
        stripeIntentClientSecret
        stripeIntentID
        title
      }
    }
  }
`;

export const GET_OUTCOMING_EXPERIENCES = gql`
  query MyOutcomingExperienceRequests($input: ExperienceRequestListFilter!) {
    myOutcomingExperienceRequests(input: $input) {
      total
      offset
      limit
      entities {
        ...ExperienceRequestFields
      }
    }
  }
  ${ExperienceRequestFields}
`;

export const ESTIMATE_EXPERIENCE_PRICE = gql`
  query EstimateExperiencePrice($input: ExperiencePriceEstimateInput!) {
    estimateExperiencePrice(input: $input) {
      price
      platformFee
      productFees {
        margin
        packaging
        pickCost
      }
    }
  }
`;
