// Components
import { components } from 'react-select';
// Types
import { OptionProps } from 'react-select';

import styles from './Option.module.scss';

const { Option: ReactSelectOption } = components;

const Option = (props: OptionProps<any, false>) => {
  return (
    <ReactSelectOption {...props}>
      <div className={styles.searchBarOption}>
        <span className={styles.searchBarOptionText}>{props.label}</span>
      </div>
    </ReactSelectOption>
  );
};

export default Option;
